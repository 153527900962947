import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";

function Slot() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
        }
        if (loading == true) return;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launch`, form)
            .then((res) => {
                if (res.data.data.errCode == "0") {
                    window.location.href = res.data.data.gameUrl;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response.data.message != "internal server error" ? err.response.data.message : "Vui lòng đăng xuất và đăng nhập lại", "error").then(
                    () => navigate("/login")
                );
                setLoading(false);
            });
    }
    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="banner-game">
                <img src={require("../../image/slots.jpg")} />
            </div>
            <div className="container">
                <div className="menu-box-game" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                    <div className="box-game">
                        <div className="box-game-image">
                            <img src={require("../../image/jili.png")} />
                        </div>
                        <div className="box-game-play" onClick={() => launchGame({ provider: "JJ", type: "SL" })}>
                            <span className="box-game-start">Chơi ngay</span>
                        </div>
                    </div>
                    <div className="box-game">
                        <div className="box-game-image">
                            <img src={require("../../image/slotag.png")} />
                        </div>
                        <div className="box-game-play" onClick={() => launchGame({ provider: "AG", type: "SL" })}>
                            <span className="box-game-start">Chơi ngay</span>
                        </div>
                    </div>
                    <div className="box-game">
                        <div className="box-game-image">
                            <img src={require("../../image/bbin.png")} />
                        </div>
                        <div className="box-game-play" onClick={() => launchGame({ provider: "GB", type: "SL" })}>
                            <span className="box-game-start">Chơi ngay</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Slot;
